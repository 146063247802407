import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import SecondaryTitle from "../common/SecondaryTitle"

const OurCustomersSection = styled.section`
  margin-bottom: 5.5rem;
  width: calc(100% - 2.5rem);
  max-width: 1000px;
  margin-inline: auto;
  @media (min-width: 40em) {
    margin-bottom: 7.5rem;
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4.5rem; /* Updated gap to 4.5rem */
  margin-top: 2rem;
  justify-items: center;

  @media (max-width: 40em) {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Change to 2 columns on smaller screens */
    gap: 1.5rem; /* Reduce gap to 1rem on mobile */
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px; /* Set a fixed height */
  overflow: hidden; /* Hide overflow to crop the image */

  img {
    width: 100%;
    height: 100%; /* Ensure the image takes full height */
    object-fit: cover; /* Crop the image to maintain aspect ratio */
  }

  @media (max-width: 40em) {
    height: 80px;
  }
`

const OurCustomers = () => {
  return (
    <OurCustomersSection>
      <SecondaryTitle>Our Great Customers</SecondaryTitle>
      <GridContainer>
        <ImageWrapper>
          <StaticImage
            src="../../images/landing-page/mirka_logo.jpg"
            alt="Mirka Logo"
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../images/landing-page/postnord_logo.png"
            alt="PostNord Logo"
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../images/landing-page/forus_logo.jpg"
            alt="Forus logo"
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../images/landing-page/cuuma_logo.png"
            alt="Cuuma Communications logo"
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../images/landing-page/garantia_logo.png"
            alt="Garantia logo"
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../images/landing-page/partio_logo.png"
            alt="Suomen partiolaiset logo"
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../images/landing-page/gofore_logo.png"
            alt="Gofore logo"
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../images/landing-page/lotus_logo.png"
            alt="Lotus demolition logo"
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../images/landing-page/vaisala_logo.png"
            alt="Vaisala logo"
          />
        </ImageWrapper>
      </GridContainer>
    </OurCustomersSection>
  )
}

export default OurCustomers
