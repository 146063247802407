import * as React from "react"
import Layout from "../components/common/Layout"
import Carousel from "../components/landing-page/carousel/Carousel"
import { graphql } from "gatsby"
import InfoBoxes from "../components/common/infoboxes/InfoBoxes"
import OfferBoxes from "../components/landing-page/offerboxes/OfferBoxes"
import PageIntro from "../components/common/intro/PageIntro"
import ApplyButton from "../components/landing-page/ApplyButton"
import Video from "../components/landing-page/video/Video"
import AboutSection from "../components/landing-page/AboutSection"
import Meta from "../components/common/Meta"
import Main from "../components/common/Main"
import ContactBox from "../components/common/formboxes/ContactBox"
import { Script } from "gatsby"
import StoriesSection from "../components/landing-page/StoriesSection"
import OurCustomers from "../components/landing-page/OurCustomers"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const ImageContainer = styled.div`
  margin-inline: auto;
  position: relative;
  margin-bottom: 5.5rem;
  width: min(1000px, 100% - 2.5rem);

  @media (min-width: 40em) {
    margin-bottom: 7.5rem;
  }
`

export const Head = ({ data }) => (
  <>
    <Meta
      title="Rebase - Experts in Software, Cloud, Data, QA, and Agile"
      description="Discover Rebase's senior and local experts and teams in software development, cloud, data, test automation and business agility."
    />
    <Script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Rebase",
          "url": "https://rebase.fi/",
          "logo": "https://rebase.fi/rebase-logo.png", 
          "description": "Rebase, established in 2020, is dedicated to uniting expertise in software, cloud, design, and agile business practices in a workplace centered on freedom and simplicity. Our goal is to propel client success and growth. As a market-oriented company committed to inclusivity and diversity, Rebase focuses on profitable, sustainable growth within the Gofore Plc ecosystem, emphasizing teamwork and empowering environments.",
          "address": [
            {
            "@type": "PostalAddress",
            "streetAddress": "Kansakoulukatu 3",
            "addressLocality": "Helsinki",
            "postalCode": "00100",
            "addressCountry": "FI"
            },
            {
              "streetAddress": "Tykistökatu 4",
              "addressLocality": "Turku",
              "postalCode": "20520",
              "addressCountry": "FI"
            }
          ],
          "contactPoint": {
            "@type": "ContactPoint",
            "name": "Juhana Huotarinen",
            "email": "juhana.huotarinen@rebase.fi",
            "telephone": "+358 40 5182663",
            "contactType": "CEO"
          },
          "sameAs": [
            "https://www.linkedin.com/company/rebase-consulting/",
            "https://www.itewiki.fi/rebase-consulting"
          ],
          "foundingDate": "2020",
          "numberOfEmployees": ${data.people.totalCount},
          "parentOrganization": {
            "@type": "Organization",
            "name": "Gofore Plc"
          }
        }
      `}
    </Script>
  </>
)

const HomePage = ({ data }) => {
  const title = [["Digital Services"], ["Crafted for", "Our Customers"]]

  return (
    <Layout>
      <Main>
        <PageIntro
          title={title}
          subheading={
            "Explore our senior, local and entrepreneurial IT consultants and teams!"
          }
          center={true}
          maxWidth="72ch"
        />
        <ApplyButton />
        <ImageContainer>
            <StaticImage
              src="../images/landing-page/rebase_nuuksio.jpg"
              alt="Photo from Rebase summer party 2023"
            />
        </ImageContainer>
        <OurCustomers />
        <OfferBoxes />
        <StoriesSection data={data} />
        <Carousel data={data} />
        <AboutSection />
        <InfoBoxes
          content={[
            {
              number: data.enps.nodes[0].enps,
              extraFront: "+",
              content: "Employee Net Promoter Score (eNPS)",
            },
            {
              number: data.enps.nodes[0].cnps,
              extraFront: "+",
              content: "Net Promoter Score (NPS)",
            },
            {
              number: data.people.totalCount,
              content: "Team members",
            },
          ]}
        />
      </Main>
      <ContactBox />
    </Layout>
  )
}

export const query = graphql`
  query landingPageImages {
    image: file(relativePath: { eq: "our-people/kustaa.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    poster: file(relativePath: { eq: "landing-page/poster.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }

    quotes: allQuoteDataJson {
      nodes {
        id
        name
        title
        quote
        image {
          childImageSharp {
            gatsbyImageData(width: 150, quality: 100, placeholder: BLURRED)
          }
        }
      }
    }

    people: allPeopleDataJson {
      totalCount
    }

    enps: allCommonJson {
      nodes {
        enps
        cnps
      }
    }

    story: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/src/data/stories/" } }
    ) {
      nodes {
        frontmatter {
          slug
          summaryTitle
          summary
          date
          summaryImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        id
      }
    }
  }
`

export default HomePage
